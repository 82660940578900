import { memo, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import { addDays, differenceInDays, subDays } from "date-fns";
import last from "lodash/last";
import { FLIGHT_TYPE, OFFER_TYPES } from "app/constants";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import { buildItineraryPreviewData } from "app/pages/.shared/ItineraryUtils";

const TravelDates = ({
	booking = {},
	isWithHotel = false,
	showDuration = false,
	showDepartureDateForInboundFlight = false,
}) => {
	const duration = booking?.duration?.value;
	const departureDate = booking?.departureDate && new Date(booking.departureDate);
	const [bookingDays, setBookingDays] = useState(null);

	let inboundLandingDate = booking.endDate;

	if (booking.offer && booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
		inboundLandingDate =
			booking.flight &&
			booking.flight.inbound &&
			booking.flight.inbound.legs &&
			booking.flight.inbound.legs.length > 0
				? last(booking.flight.inbound.legs).to.landingDate
				: undefined;
	}
	// pour les produits VP de type AO on a pas le endDate dans les params envoyé par VP
	if (
		booking.offer?.type === OFFER_TYPES.ACCOMODATION_ONLY &&
		inboundLandingDate === undefined &&
		departureDate
	) {
		inboundLandingDate = addDays(departureDate, duration);
	}

	const endDate = inboundLandingDate && new Date(inboundLandingDate);

	const flightInboundArrivalDaysCounter = useMemo(() => {
		const flightInboundDetailData = buildItineraryPreviewData(booking?.flight?.inbound);
		return (
			flightInboundDetailData?.find(flight => flight.flightType === FLIGHT_TYPE.ARRIVAL)?.data
				?.arrivalDaysCounter || 0
		);
	}, [booking?.flight?.inbound]);
	const flightOutboundArrivalDaysCounter = useMemo(() => {
		const flightOutboundDetailData = buildItineraryPreviewData(booking?.flight?.outbound);
		return (
			flightOutboundDetailData?.find(flight => flight.flightType === FLIGHT_TYPE.ARRIVAL)
				?.data?.arrivalDaysCounter || 0
		);
	}, [booking?.flight?.outbound]);

	useEffect(() => {
		if (endDate) {
			setBookingDays(differenceInDays(endDate, departureDate) + 1);
		}
	}, [endDate, departureDate]);

	return departureDate > 0 ? (
		<div className="travel-dates">
			<div className="travel-dates__dates" data-testid="detail-travel-dates">
				{departureDate && inboundLandingDate && bookingDays && duration ? (
					<>
						<FormattedDate
							timeZone="UTC"
							value={departureDate}
							month="short"
							day="numeric"
							weekday="short"
							year="numeric"
						/>
						{flightOutboundArrivalDaysCounter > 0 && (
							<sup className="travel-dates__arrival-days-counter">
								+{flightOutboundArrivalDaysCounter}
							</sup>
						)}
						{" - "}
						<FormattedDate
							timeZone="UTC"
							value={
								showDepartureDateForInboundFlight
									? subDays(
											new Date(inboundLandingDate),
											flightInboundArrivalDaysCounter
									  )
									: inboundLandingDate
							}
							month="short"
							day="numeric"
							weekday="short"
							year="numeric"
						/>
						{flightInboundArrivalDaysCounter > 0 && (
							<sup className="travel-dates__arrival-days-counter">
								+{flightInboundArrivalDaysCounter}
							</sup>
						)}
					</>
				) : (
					<LoaderBar height={10} />
				)}
			</div>
			{duration > 0 && showDuration && (
				<div className="travel-dates__duration" data-testid="detail-travel-duration">
					{departureDate && inboundLandingDate && bookingDays && duration ? (
						<FormattedMessage
							id={
								isWithHotel
									? "sdp.search.summary.duration"
									: "sdp.search.summary.duration.without.hotel"
							}
							values={{ days: bookingDays, nights: duration }}
						/>
					) : (
						<LoaderBar height={10} />
					)}
				</div>
			)}
		</div>
	) : (
		<div className="travel-dates travel-dates--error">
			<FormattedMessage id="sdp.search.departure.date.input.label" />
		</div>
	);
};

TravelDates.propTypes = {
	booking: PropTypes.object,
	isWithHotel: PropTypes.bool,
	showDuration: PropTypes.bool,
	showDepartureDateForInboundFlight: PropTypes.bool,
};

export default memo(TravelDates);
