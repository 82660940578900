import { Fragment, memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconNegoCheck from "app/pages/.shared/IconNegoCheck";
import { NEGOCIATED_TYPES } from "app/constants";
import IconNegoBed from "app/pages/.shared/IconNegoBed";
import IconNegoTransfer from "app/pages/.shared/IconNegoTransfer";
import IconNegoDinner from "app/pages/.shared/IconNegoDinner";
import get from "lodash/get";
import "./NegociatedItem.scss";
import IconNegoTrain from "app/pages/.shared/IconNegoTrain";
import IconNegoTransport from "app/pages/.shared/IconNegoTransport";
import { useIntl } from "react-intl";
import LineClamp from "app/pages/.shared/LineClamp/LineClamp";
import { htmlSanitize } from "app/utils/sanitize";
import IconPlane2 from "app/pages/.shared/IconPlane2";

const NEGOTIATED_ICONS = {
	[NEGOCIATED_TYPES.ACCOMMODATION]: <IconNegoBed />,
	[NEGOCIATED_TYPES.FLIGHT]: <IconPlane2 />,
	[NEGOCIATED_TYPES.TRANSFER]: <IconNegoTransfer />,
	[NEGOCIATED_TYPES.BOARD]: <IconNegoDinner />,
	[NEGOCIATED_TYPES.TRAIN]: <IconNegoTrain />,
	[NEGOCIATED_TYPES.TRANSPORTATION]: <IconNegoTransport />,
};

const NegociatedItem = ({
	type,
	label,
	enableLineClamp,
	lineClampFullText,
	isTransportOnly,
	specialOffer,
	offerWithTransportLabel,
	labelPrefix,
}) => {
	const specialOfferTitleColor = get(specialOffer, "ui.titleColor");
	const specialOfferPictoUrl = get(specialOffer, "ui.pictoUrl");

	const intl = useIntl();

	const negocatedSuffix = intl.formatMessage(
		{ id: "search.engine.negociated.with.offer" },
		{ offer: offerWithTransportLabel }
	);

	let labelToDisplay = label;

	if (isTransportOnly && type !== NEGOCIATED_TYPES.SPECIAL_OFFER) {
		labelToDisplay = `${label} (${negocatedSuffix})`;
	}

	let picto = <IconNegoCheck />;
	if (type === NEGOCIATED_TYPES.SPECIAL_OFFER && specialOfferPictoUrl) {
		picto = <img src={specialOfferPictoUrl} />;
	} else if (type && NEGOTIATED_ICONS[type]) {
		picto = NEGOTIATED_ICONS[type];
	}

	const negoClass = classNames("negociated-item", {
		"negociated-item--other": !type || type === NEGOCIATED_TYPES.OTHER,
		"negociated-item--special-offer": type === NEGOCIATED_TYPES.SPECIAL_OFFER,
	});

	return (
		<Fragment>
			<div
				className={negoClass}
				style={{ color: type === NEGOCIATED_TYPES.SPECIAL_OFFER && specialOfferTitleColor }}
			>
				<i className="negociated-item__picto">{picto}</i>{" "}
				<div className="negociated-item__main">
					{enableLineClamp ? (
						<LineClamp
							lineClampFullText={lineClampFullText}
							enableShowMore={true}
							lineCount={1}
							text={
								<span
									className="negociated-item__label"
									dangerouslySetInnerHTML={{
										__html: htmlSanitize(labelToDisplay),
									}}
								/>
							}
						/>
					) : (
						<>
							<span
								className="negociated-item__label"
								dangerouslySetInnerHTML={{
									__html: htmlSanitize(labelToDisplay),
								}}
							/>
							{labelPrefix}
						</>
					)}
				</div>
			</div>
			{type === NEGOCIATED_TYPES.SPECIAL_OFFER && (
				<span
					className="negociated-item__special-offer-details"
					style={{ color: specialOfferTitleColor }}
					dangerouslySetInnerHTML={{ __html: htmlSanitize(specialOffer.details) }}
				/>
			)}
		</Fragment>
	);
};

NegociatedItem.propTypes = {
	type: PropTypes.string,
	offerWithTransportLabel: PropTypes.string,
	label: PropTypes.string,
	labelPrefix: PropTypes.element,
	isTransportOnly: PropTypes.bool,
	specialOffer: PropTypes.object,
	lineClampFullText: PropTypes.object,
	enableLineClamp: PropTypes.bool,
};

NegociatedItem.defaultProps = {
	specialOffer: {},
};

export default memo(NegociatedItem);
